import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/layout'
import { Heading, Para } from 'components'
import SEO from 'components/seo'

// const image = <Image source="http://placehold.it/600x200" />

// const centreContent = (
//   <>
//     <Heading level={2}>Section Heading</Heading>
//     <Para>
//       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
//       assumenda{' '}
//       <Link style={{ color: '#73D6CF' }} to="">
//         excepturi maxime
//       </Link>{' '}
//       molestiae velit accusamus doloribus impedit, provident ullam. Natus sint
//       cupiditate obcaecati porro consectetur, excepturi laborum facilis omnis
//       mollitia?
//     </Para>
//     <Para>
//       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
//       assumenda excepturi maxime molestiae velit accusamus doloribus impedit,
//       provident ullam. Natus sint cupiditate obcaecati porro consectetur,
//       excepturi laborum facilis omnis mollitia?
//     </Para>
//     <Para>
//       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
//       assumenda excepturi maxime molestiae velit accusamus doloribus impedit,
//       provident ullam. Natus sint cupiditate obcaecati porro consectetur,
//       excepturi laborum facilis omnis mollitia?
//     </Para>
//     <Heading level={2}>Section Heading</Heading>
//     <Para>
//       Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
//       assumenda excepturi maxime molestiae velit accusamus doloribus impedit,
//       provident ullam. Natus sint cupiditate obcaecati porro consectetur,
//       excepturi laborum facilis omnis mollitia?
//     </Para>
//   </>
// )

const IndexPage = ({ uri }) => (
  <Layout {...(uri === '/' ? { homepage: true } : {})}>
    <SEO title="Ography" keywords={[`web development`, `website`, `react`]} />
    {/* <Structure centre={centreContent} right={image} sticky /> */}
  </Layout>
)

export default IndexPage
